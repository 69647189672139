import { Link } from "gatsby"
import * as React from "react"
import { Col, Container, Row } from "react-bootstrap"
import Footer from "../../components/footer"
import Head from "../../components/header"
import Layout from "../../components/layout"
import TopMenu from "../../components/menu"

const AboutPage = () => (
  <Layout>
    <Head pageTitle="Company - GAZIRU, Inc." pageLang="en" />
    <TopMenu />
    <Container>
      <Row>
        <Col sm={1}>
        </Col>
        <Col sm={10}>
          <h4>Company</h4>
          <table>
            <tbody>
              <tr>
                <td>Name</td>
                <td>GAZIRU, Inc.</td>
              </tr>
              <tr>
                <td>Business</td>
                <td>Providing individual identification and species recognition functions through images</td>
              </tr>
              <tr>
                <td>Representative</td>
                <td>Shigekazu Fukuzawa</td>
              </tr>
              <tr>
                <td>Established in</td>
                <td>April 1st 2020</td>
              </tr>
              <tr>
                <td>Office location</td>
                <td>Todoroki 1-33-16, Setagaya-ku, Tokyo<br />
                  158-0082
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <br />


          <iframe loading="lazy" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3243.9352222659395!2d139.64598425078728!3d35.60466444167773!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6018f455af0fb3e3%3A0x3c73f307cfb4d9e!2s1-ch%C5%8Dme-33-16%20Todoroki%2C%20Setagaya%20City%2C%20Tokyo%20158-0082!5e0!3m2!1sen!2sjp!4v1657805346934!5m2!1sen!2sjp" width="100%" height="300" frameBorder="0"></iframe>

          <br />
          <br />
          <hr />
          <br />
          <br />
          <p>For inquiries and requests for quotes, please use <Link className="text-decoration-none ms-2" to="/notification/contact/en">this contact form</Link>.</p>
          <br />
          <br />
          <p><Link className="text-decoration-none" to="/">Home</Link></p>
        </Col>
        <Col sm={1}>
        </Col>
      </Row>
    </Container>
    <Footer />
  </Layout>
)

export default AboutPage
